import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  AccordionButtonProps,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';

export const hoverSideMenuItemStyle = {
  boxShadow: 'none',
  color: 'qGray.300',
};

const focusSideMenuItemStyle = {
  boxShadow: 'sideMenuInset',
};

export interface SideMenuLinkRaw {
  i18nKey: string;
  to?: string;
}

export interface SideMenuLink extends Omit<SideMenuLinkRaw, 'i18nKey'> {
  text?: string;
  i18nKey?: string;
  divider?: boolean;
  items?: SideMenuLink[];
}

interface SideMenuProps {
  links: SideMenuLink[];
  action?: ReactNode;
}

interface SideMenuProps {
  links: SideMenuLink[];
  location: Location;
  level?: number;
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ links, location, level = 1 }) => {
  const buttonStyles: AccordionButtonProps = {
    paddingBlock: 4,
    paddingInline: 5,
    fontWeight: level <= 2 ? 'bold' : 'normal',
    textAlign: 'left',
  };

  const defaultIndex =
    level === 1 && location.pathname.startsWith('/articles')
      ? links.findIndex((link) => link.to === '/articles')
      : false;

  return (
    <Accordion
      allowToggle
      sx={{ 'a[aria-current]': { color: 'brand.500' } }}
      {...(typeof defaultIndex === 'number' && { defaultIndex: [defaultIndex] })}
    >
      {links.map((link) => {
        const { text, to, items } = link;
        const hasChildren = !!items?.length;

        return (
          <AccordionItem
            key={text}
            sx={{
              ...(level === 1 && { '&:first-of-type': { borderTop: 0 } }),
              '&:last-of-type': { borderBottom: 0 },
              ...(level >= 3 && { border: 0 }),
            }}
          >
            <AccordionButton
              {...(!hasChildren && { as: GatsbyLink, to })}
              justifyContent="space-between"
              _focus={focusSideMenuItemStyle}
              _hover={hoverSideMenuItemStyle}
              {...buttonStyles}
            >
              <Text {...(hasChildren && { as: GatsbyLink, to })} noOfLines={2} partiallyActive>
                {text}
              </Text>
              {hasChildren && <AccordionIcon boxSize="1.25em" />}
            </AccordionButton>
            {hasChildren && (
              <AccordionPanel p={0} background="blackAlpha.50">
                <SideMenu links={items} level={level + 1} location={location} />
              </AccordionPanel>
            )}
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default SideMenu;
